<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 12H4.5V44H36.5V40H8.5V12Z"
      fill="#E9EBED"
    />
    <path
      d="M8.5 12H4.5V44H36.5V40H8.5V12Z"
      fill="url(#paint0_linear_2285_10266)"
    />
    <path
      d="M12.5 4V36H44.5V4H12.5ZM27.52 20.32C28.34 18.86 29.88 18 30.78 16.72C31.74 15.36 31.2 12.84 28.5 12.84C26.74 12.84 25.86 14.18 25.5 15.3L22.76 14.16C23.52 11.92 25.54 10 28.48 10C30.94 10 32.64 11.12 33.5 12.52C34.24 13.72 34.66 15.98 33.52 17.66C32.26 19.52 31.06 20.08 30.4 21.28C30.14 21.76 30.04 22.08 30.04 23.64H27C27.02 22.82 26.88 21.48 27.52 20.32ZM26.4 27.9C26.4 26.72 27.34 25.82 28.5 25.82C29.68 25.82 30.58 26.72 30.58 27.9C30.58 29.06 29.7 30 28.5 30C27.34 30 26.4 29.06 26.4 27.9Z"
      fill="#E9EBED"
    />
    <path
      d="M12.5 4V36H44.5V4H12.5ZM27.52 20.32C28.34 18.86 29.88 18 30.78 16.72C31.74 15.36 31.2 12.84 28.5 12.84C26.74 12.84 25.86 14.18 25.5 15.3L22.76 14.16C23.52 11.92 25.54 10 28.48 10C30.94 10 32.64 11.12 33.5 12.52C34.24 13.72 34.66 15.98 33.52 17.66C32.26 19.52 31.06 20.08 30.4 21.28C30.14 21.76 30.04 22.08 30.04 23.64H27C27.02 22.82 26.88 21.48 27.52 20.32ZM26.4 27.9C26.4 26.72 27.34 25.82 28.5 25.82C29.68 25.82 30.58 26.72 30.58 27.9C30.58 29.06 29.7 30 28.5 30C27.34 30 26.4 29.06 26.4 27.9Z"
      fill="url(#paint1_linear_2285_10266)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2285_10266"
        x1="279.889"
        y1="-157.818"
        x2="36.997"
        y2="66.2534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2285_10266"
        x1="279.889"
        y1="-157.818"
        x2="36.997"
        y2="66.2534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-quiz',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
